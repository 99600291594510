import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";
const API_PUBLIC = "/api/Prueba/V1/query";

export default {
  getAllSienAnemia: (anio, periodo_ini, periodo_fin) => {

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "q_web_select_sienanemianinios",
          type: "string"
        },
        {
          name: "@ANIO",
          value: anio,
          type: "string"
        },
        {
          name: "@PERIODOINI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIODOFIN",
          value: periodo_fin,
          type: "string"
        },
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },  

  getAllSienAnemiaFiltros: () => {

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "q_web_select_anemianinio_filtros",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }, 

  getAllSienAnemiaFiltrosPublico: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "q_web_select_anemianinio_filtros",
          type: "string"
        }
      ]
    };

    return post(API_PUBLIC, request);
  },
  
  editOne: (payload, idsienanemia) => {
    const request = {
      _type: 3,
      _table: "reg_sienanemia",
      _petitions: [
        {
          name: "anio",
          value: payload.anio,
          type: "string"
        },
        {
          name: "periodo_ini",
          value: payload.periodo_ini,
          type: "string"
        },
        {
          name: "periodo_fin",
          value: payload.periodo_fin,
          type: "string"
        },
        {
          name: "nro_evaluados",
          value: payload.nro_evaluados,
          type: "string"
        },
        {
          name: "nro_anemia_leve",
          value: payload.nro_anemia_leve,
          type: "string"
        },
        {
          name: "porc_anemia_leve",
          value: payload.porc_anemia_leve,
          type: "string"
        },
        {
          name: "nro_anemia_moderada",
          value: payload.nro_anemia_moderada,
          type: "string"
        },
        {
          name: "porc_anemia_moderada",
          value: payload.porc_anemia_moderada,
          type: "string"
        },
        {
          name: "nro_anemia_severa",
          value: payload.nro_anemia_severa,
          type: "string"
        },
        {
          name: "porc_anemia_severa",
          value: payload.porc_anemia_severa,
          type: "string"
        },
      ]
    };

    return put(API_SERVICE + "/" + idsienanemia, request);
  },
};