<template>
  <div style="display: none">
    <div id="printMapaCalor">
      <PrintContainer
        tituloReporte="REPORTE DE MAPA DE CALOR DE NIÑOS MENORES DE 3 AÑOS CON ANEMIA"
      >
        <table class="items-center w-full">
          <thead class="theadprint text-xs p-5">
            <tr>
              <th class="text-center p-3 border border-black">NRO</th>
              <th class="text-center p-3 border border-black">DISTRITO</th>
              <th class="text-center p-3 border border-black">NUMERADOR</th>
              <th class="text-center p-3 border border-black">DENOMINADOR</th>
              <th class="text-center p-3 border border-black">PORCENTAJE</th>
            </tr>
          </thead>
          <tbody class="text-xs">
            <tr v-for="(row, index) in establecimientos" :key="index">
              <td class="border border-gray-900 text-center py-1">
                {{ index + 1 }}
              </td>
              <td class="border border-gray-900 px-2">
                {{ row.nombre }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ row.numerador }}
              </td>
              <td class="border border-gray-900 text-center px-2">
                {{ row.denominador }}
              </td>
              <td class="border border-gray-900 text-center px-2">{{ row.resultado }} %</td>
            </tr>
            <tr>
              <td class="border border-gray-900 text-center px-2 font-bold" colspan="2">TOTAL</td>
              <td class="border border-gray-900 text-center px-2 font-bold">
                {{ totalRegistros.numerador }}
              </td>
              <td class="border border-gray-900 text-center px-2 font-bold">
                {{ totalRegistros.denominador }}
              </td>
              <td class="border border-gray-900 text-center px-2 font-bold">100%</td>
            </tr>
          </tbody>
          <PrintFooter>
            <tr>
              <td colspan="100%" class="border-0">
                Fuente: PADRÓN NOMINAL, HISMINSA y CNV actualizado a la fecha: 11/11/2021
              </td>
            </tr>
          </PrintFooter>
        </table>
      </PrintContainer>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import PrintContainer from "./PrintContainer.vue";
import PrintFooter from "@/components/PrintFooter.vue";

export default {
  components: {
    PrintContainer,
    PrintFooter
  },
  props: {
    establecimientos: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const totalRegistros = reactive({
      numerador: 0,
      denominador: 0
    });

    onMounted(() => {
      // let suma = 0;
      console.log(props.establecimientos);
      let sumaNum = 0;
      let sumaDen = 0;
      props.establecimientos.map((data) => {
        sumaNum = sumaNum + Number(data.numerador);
        sumaDen = sumaDen + Number(data.denominador);
      });
      totalRegistros.numerador = sumaNum;
      totalRegistros.denominador = sumaDen;
    });

    return {
      totalRegistros
    };
  }
};
</script>

<style scoped></style>
