export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "DISTRITO",
    dataIndex: "nombre",
    key: "nombre",
    sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    width: 140
  },
  {
    title: "PORCENTAJE",
    key: "porcentaje",
    dataIndex: "porcentaje",
    width: 90,
    sorter: (a, b) => a.porcentaje - b.porcentaje,
    slots: { customRender: "porcentaje" },
    defaultSortOrder: "descend"
  },
  {
    title: "NUM/DEN",
    key: "numeradordenominador",
    width: 50,
    slots: { customRender: "numeradordenominador" }
  }
];
